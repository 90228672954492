import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { handleLoadWindow, scrollTo, scrollTop } from '../../helpers/common/screen';
import './EventList.css';
import { Event } from './Event';
export const EventList = ({ eventList }) => {
  const { id } = useParams();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    handleLoadWindow(() => {
      scrollTo(`.event-${id}`, '.events');
    });
  }, [id]);

  return (
    <div className="events">
      {eventList.map(event => (
        <Event
          key={event.id}
          event={event}
          isCurrentEvent={id === event.id}
        />
      ))}
    </div>
  );
};

EventList.propTypes = {
  eventList: PropTypes.array.isRequired,
};
