import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { handleLoadWindow, scrollTo, scrollTop } from '../../helpers/common/screen';
import { Album } from './Album';

import './AlbumList.css';

export const AlbumList = ({
  albumList,
}) => {
  const { id } = useParams();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    handleLoadWindow(() => {
      scrollTo(`.album-${id}`, '.albums');
    });
  }, [id]);

  return (
    <div className="albums">
      {albumList.map(album => (
        <Album
          key={album.id}
          album={album}
          isCurrentAlbum={id === album.id}
        />
      ))}
    </div>
  );
};

AlbumList.propTypes = {
  albumList: PropTypes.array.isRequired,
};
