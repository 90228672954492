import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TrackList } from '../TrackList/TrackList';
import { Image } from '../common/Image';
import { Link } from '../common/Link';

export const Album = ({ album, isCurrentAlbum }) => {
  return (
    <div
      className={classNames(
        'album',
        `album-${album.id}`,
        isCurrentAlbum && 'current',
        'inline-block',
      )}
    >
      <Link
        to={isCurrentAlbum ? '/albums' : `/albums/${album.id}`}
        className="block"
      >
        <Image
          className="cover block"
          src={album.imgPath}
          alt={album.title}
        />
        <p className="description">{album.title}</p>
      </Link>
      {isCurrentAlbum && (
        <TrackList
          trackList={album.tracks}
          showLyrics={false}
          autoScroll={false}
        />
      )}
    </div>
  );
};

Album.propTypes = {
  album: PropTypes.object.isRequired,
  isCurrentAlbum: PropTypes.bool,
};
