import PropTypes from 'prop-types';
import classNames from 'classnames';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRandom } from '../../helpers/common/math';
import { getConfig } from '../../helpers/data';
import { Link } from '../common/Link';
import { Image } from '../common/Image';
import { Icon } from '../common/Icon';

const getAltImage = () => {
  const { altImageList } = getConfig();

  return altImageList[getRandom(0, altImageList.length - 1)];
};

export const Event = ({ event, isCurrentEvent }) => {
  const navigate = useNavigate();

  const handleClickEvent = event => {
    if (event) {
      navigate(`/events/${event.id}`);
    } else {
      navigate('/events');
    }
  };

  const src = useMemo(() => {
    return event.imgPath || getAltImage();
  }, [event.imgPath]);

  const classes = useMemo(() => {
    return [
      'block',
      { fallback: !event.imgPath },
      { sepia: !(event.imgPath || getRandom(0, 3)) },
      { saturate: !(event.imgPath || getRandom(0, 3)) },
      { 'hue-rotate': !(event.imgPath || getRandom(0, 3)) },
      { invert: !(event.imgPath || getRandom(0, 3)) },
      { rotateY: !(event.imgPath || getRandom(0, 3)) },
      { mirror: !event.imgPath && !!getRandom(0, 1) },
    ];
  }, [event.imgPath]);

  return (
    <div
      className={classNames(
        'event',
        `event-${event.id}`,
        isCurrentEvent && 'current',
        'inline-block',
      )}
    >
      <Link
        to={event.url}
        className="block"
        onClick={e => {
          e.preventDefault();
          handleClickEvent(isCurrentEvent ? '' : event);
        }}
      >
        <Image
          className={classNames(classes)}
          src={src}
          alt={event.title}
        />
      </Link>
      <p className="description">
        <Icon id="map" />
        {' '}
        <Link
          className="underline"
          to={event.url}
          onClick={() => {
            handleClickEvent(event);
          }}
        >
          {event.title}
        </Link>
      </p>
      <p className="description">
        <Icon id="calendar3" />
        {format(new Date(event.date), 'dd.MM.yyyy')}
      </p>
    </div>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  isCurrentEvent: PropTypes.bool,
};
